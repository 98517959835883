import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  ListItem,
  // OrderedList,
  UnorderedList,
  Button,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import BaseLayout from '../../layout/base'
import {
  Heading1,
  Heading2,
  Heading3,
  // Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../../components/ContentWithStyles/TransformToChakra'
import SEO from '../../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const [word, setWord] = useState('Palabra')
  const selectRandomItem = someList =>
    someList[Math.floor(Math.random() * someList.length)]

  useEffect(() => {
    setWord(selectRandomItem(data.wordsJson.spanish.adjectives))
  }, [data.wordsJson.spanish.adjectives])

  return (
    <BaseLayout>
      <SEO
        title="Generador de adjetivos aleatorios"
        titleSeo="Generador de adjetivos aleatorios"
        description="Herramienta para generar adjetivos al azar en español."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[{ name: 'Palabras', url: '/palabras/' }]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {word}
            </Text>
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de adjetivos aleatorios
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() =>
                setWord(selectRandomItem(data.wordsJson.spanish.adjectives))
              }
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>Generador de adjetivos aleatorios</Heading1>
        <Paragraph>
          Este generador de adjetivos aleatorios del idioma espa&ntilde;ol es
          una simple herramienta online que permite obtener un adjetivo de forma
          aleatoria con tan solo presionar un bot&oacute;n. El generador
          aleatorio tiene un funcionamiento muy simple pero efectivo, esto se
          debe a que intenta cumplir un objetivo muy sencillo y presentar ante
          el usuario un panel de complicadas configuraciones e interminables
          pasos a seguir ser&iacute;a contraproducente, ya que
          transformar&iacute;a una tarea muy elemental en un proceso tedioso.
        </Paragraph>
        <Paragraph>
          Esta herramienta de generaci&oacute;n de adjetivos al azar cuenta con
          una lista predefina de miles de palabras del idioma espa&ntilde;ol que
          son adjetivos de todos los tipos, a partir de la cual se hace una
          selecci&oacute;n aleatoria con cada uso del bot&oacute;n para generar
          nuevas palabras.
        </Paragraph>
        <Paragraph>
          Al no tener par&aacute;metros a configurar ni ajustes a cambiar, el
          generador de adjetivos al azar resulta ser una utilidad que no
          requiere instrucciones de uso ni profundas explicaciones acerca de su
          naturaleza.
        </Paragraph>
        <Heading2>
          Para qu&eacute; utilizar los adjetivos generados aleatoriamente
        </Heading2>
        <Paragraph>
          Quiz&aacute; pueda parecer que generar adjetivos aleatoriamente no
          cumple ning&uacute;n prop&oacute;sito &uacute;til, que no hay uso para
          una utilidad tan simple, pero si est&aacute;s aqu&iacute;,
          posiblemente hayas llegado buscando justamente este generador para
          hacer algo en particular, dado que es tan espec&iacute;fico y acotado
          que dif&iacute;cilmente te hayas confundido y acabado aqu&iacute; por
          error, sin tener ning&uacute;n uso para los adjetivos aleatorios que
          puedas generar.
        </Paragraph>
        <Paragraph>
          Hay muchas actividades grupales y juegos que requieren del uso de
          palabras seleccionadas de forma aleatoria, y este generador resulta de
          especial provecho cuando esas palabras necesitan ser
          espec&iacute;ficamente adjetivos, aunque en casos diferentes
          quiz&aacute; tambi&eacute;n encuentres en este sitio web otros
          generadores que puedan serte de utilidad: de nombres, de
          pa&iacute;ses, de adjetivos, de palabras, de palabras en
          ingl&eacute;s, etc.
        </Paragraph>
        <Paragraph>
          El delegar la selecci&oacute;n a una aplicaci&oacute;n de software
          facilita el evitar sesgos y preferencias personales, lo cu&aacute;l es
          especialmente importante en actividades grupales que requieran la
          selecci&oacute;n de un adjetivo al azar sin dar ventaja a
          ning&uacute;n participante en concreto.
        </Paragraph>
        <Paragraph>
          Otro uso que se le puede dar a esta herramienta de generaci&oacute;n
          de adjetivos aleatorios es la de construir historias y relatos, dado
          que muchas veces nos vemos en el escenario de estar escribiendo un
          guion cuando de repente nos gustar&iacute;a agregar alg&uacute;n tipo
          de adjetivo a un personaje, objeto o lugar, pero no queremos
          repetirnos respecto a los que ya utilizamos, y adem&aacute;s nos
          encontramos faltos de creatividad.
        </Paragraph>
        <Paragraph>
          Un generador aleatorio puede ayudar mucho en contextos donde es
          necesaria la creatividad o generaci&oacute;n de ideas, como lo es el{' '}
          <em>brainstorming</em>, donde contar con palabras aleatorias nos
          permitir&aacute; dar origen a ideas y descripciones con facilidad, sin
          perder tiempo ni energ&iacute;a en intentar dar con nuevos e
          interesantes adjetivos que podamos emplear.
        </Paragraph>
        <Heading2>
          &iquest;Qu&eacute; son los adjetivos? Concepto y definici&oacute;n
        </Heading2>
        <Paragraph>
          Ya hemos hablado largo y tendido acerca del generador de adjetivos
          aleatorios, su uso y su utilidad, pero repasemos ahora qu&eacute; son
          los adjetivos, c&oacute;mo se utilizan y cu&aacute;les son las
          diferentes clasificaciones o categor&iacute;as existentes para los
          mismos.
        </Paragraph>
        <Paragraph>
          Los adjetivos son palabras que sirven como complemento para el
          sustantivo, por lo que siempre est&aacute;n acompa&ntilde;ados. La
          funci&oacute;n de adjetivo es proveer informaci&oacute;n adicional
          acerca del sustantivo al que hacen referencia, matizar o puntualizar
          cualidades espec&iacute;ficas o bien simplemente dar m&aacute;s
          detalles acerca del mismo.
        </Paragraph>
        <Paragraph>
          Los adjetivos tienen el objetivo de agregar informaci&oacute;n sobre
          el sustantivo al que acompa&ntilde;an, dando contexto y profundidad.
          Algunos ejemplos son: auto rojo, edificio alto, persona
          estadounidense, lobo feroz, avi&oacute;n grande, m&uacute;sica
          cl&aacute;sica, parte secundaria, plataforma movediza, zorro
          escurridizo, vino tinto, condimento picante, ciudadano ejemplar, etc.
        </Paragraph>
        <Paragraph>
          Algo importante es que, en el idioma espa&ntilde;ol, el adjetivo debe
          respetar el g&eacute;nero y n&uacute;mero del sustantivo al que hace
          compa&ntilde;&iacute;a, lo cual muchas veces resulta algo desafiante
          para hablantes no nativos que est&aacute;n aprendiendo el idioma, pero
          es algo natural y que no requiere esfuerzo alguno para quienes lo
          tienen como lengua materna. Es decir, el adjetivo debe estar en
          singular o plural, y ser femenino o masculino, en concordancia con el
          sustantivo al que complementa.
        </Paragraph>
        <Heading3>Clasificaci&oacute;n de los adjetivos</Heading3>
        <Paragraph>
          Al pedirle a una persona ejemplos de adjetivos al azar, por lo general
          obtendremos una serie de adjetivos calificativos, porque son los
          m&aacute;s frecuentes y los que la mayor&iacute;a de personas asocian
          directamente con el concepto de &ldquo;adjetivo&rdquo;, pero la
          realidad es que existen m&uacute;ltiples categor&iacute;as o clases,
          que diferencian de forma clara los distintos tipos de adjetivos que
          nos podemos encontrar en el idioma espa&ntilde;ol.
        </Paragraph>
        <Paragraph>Tipos de adjetivos:</Paragraph>
        <UnorderedList>
          <ListItem>
            <strong>Calificativos</strong>: los m&aacute;s populares, cumplen la
            funci&oacute;n de describir y agregar detalles acerca de la cualidad
            de un objeto o entidad. Ejemplos: feo, verde, inteligente, refinado,
            valiente, bonito, peludo, azulado, cauto, malcriado, celeste,
            considerado, infantil, perfecto, dif&iacute;cil.
          </ListItem>
          <ListItem>
            <strong>Numerales</strong>: entran dentro del grupo de adjetivos
            determinativos, que se encargan de complementar al sustantivo
            agregando informaci&oacute;n sobre su cantidad. Tanto en el caso de
            adjetivos contables como en el de los no contables, los adjetivos
            numerales detallan la cantidad del mismo. Ejemplos: dos caballos, un
            pez, una manada, medio litro de agua, cuarto de hora, &uacute;ltimo
            d&iacute;a, doble pirueta.
          </ListItem>
          <ListItem>
            <strong>Demostrativos</strong>: son un tipo de adjetivos que tienen
            la tarea de indicar la relaci&oacute;n de distancia f&iacute;sica
            del sustantivo relativa al emisor. Es decir, sirve para que el
            emisor agregue informaci&oacute;n acerca de la distancia entre
            &eacute;ste y la sustantivo al que se est&aacute; haciendo
            referencia. La distancia es relativa al emisor, por lo que
            variar&aacute; el adjetivo a utilizar cuando var&iacute;e la
            posici&oacute;n del objeto o del emisor, o ambos. Ejemplos: esa
            zapatilla, estas medias, aquella escoba, aquel letrero, este perro,
            esos malandros.
          </ListItem>
          <ListItem>
            <strong>Posesivos:</strong> tal y como su nombre nos indica, los
            adjetivos posesivos hacen referencia a la posesi&oacute;n o
            pertenencia del sustantivo en relaci&oacute;n al sujeto. Dentro de
            esta clasificaci&oacute;n, podemos a su vez hablar de dos
            subcategor&iacute;as: los que van delante del sustantivo, y los que
            van detr&aacute;s de &eacute;ste (pronombres posesivos). Ejemplos:
            nuestra relaci&oacute;n, mi oportunidad, su talento, vuestra
            avaricia, tus deudas, mis flautas, nuestras pulseras; el calefactor
            es nuestro, este cord&oacute;n es m&iacute;o, aquel desastre es
            tuyo, doce huevos son vuestros, la responsabilidad es tuya, la culpa
            es m&iacute;a, etc.
          </ListItem>
          <ListItem>
            <strong>Indefinidos:</strong> se trata de aquellos adjetivos que no
            aportan una descripci&oacute;n del sustantivo, sino que en su lugar
            se encargan de especificar su alcance de forma aproximada y sin
            exactitud. Son parte de la clase de adjetivos determinativos, y son
            muy frecuentes en el uso de cantidades un tanto subjetivas, en donde
            la imprecisi&oacute;n permite al receptor interpretar con cierta
            libertad a qu&eacute; cantidad exacta se hace referencia o
            cu&aacute;l es la cantidad final necesaria. Ejemplos: alg&uacute;n
            d&iacute;a, demasiados patos, tantos problemas, unas ovejas, cierto
            aroma, tal persona, varios acontecimientos, otros caminos,
            cualquiera, ambos, cada, etc.
          </ListItem>
          <ListItem>
            <strong>Art&iacute;culos:</strong> son adjetivos cuya funci&oacute;n
            consisten en determinar al sustantivo, por lo que siempre coinciden
            con &eacute;ste en g&eacute;nero y n&uacute;mero. En esta
            clasificaci&oacute;n nos encontramos con pocos integrantes,
            as&iacute; que resulta m&aacute;s sencillo enumerarnos que
            explicarlos en detalle. Dentro de los art&iacute;culos determinados
            nos encontramos con: el, la, los, las. Y por su parte, los
            art&iacute;culos indeterminados son: un/uno, unos, una, unas.
          </ListItem>
        </UnorderedList>
        <Paragraph>
          La lista no pretende ser exhaustiva ni hacer referencia a todos y cada
          uno de los elementos disponibles en el generador de adjetivos
          aleatorios, existen m&aacute;s tipos y clasificaciones (adem&aacute;s
          de subdivisiones) que aqu&iacute; no fueron mencionados, pero que
          s&iacute; est&aacute;n presente en la lista que utiliza el generador
          de adjetivos al azar.
        </Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordsJson {
      spanish {
        adjectives
      }
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
